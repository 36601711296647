import styled from "styled-components"

const HomePageDiv = styled.div`

`;

export function HomePage() {
    return <HomePageDiv>
        <h1>Static Template</h1>
        <p>
            Lorem ipsum 
            Lorem ipsum 
            Lorem ipsum 
            Lorem ipsum 
            Lorem ipsum 
            Lorem ipsum 
            Lorem ipsum 
            Lorem ipsum 
            Lorem ipsum 
            Lorem ipsum 
            Lorem ipsum 
            Lorem ipsum 
            Lorem ipsum 
            Lorem ipsum 
            Lorem ipsum 
            Lorem ipsum 
            Lorem ipsum 
            Lorem ipsum 
            Lorem ipsum 
        </p>
    </HomePageDiv>;
}